import React from 'react';
import Input from 'components/input';
import { rightArrowIcon } from 'resources/images';
import styles from './styles.module.css';

const Footer = () => {
  const footerContactUsData = [
    ['Offices', 'Municipality', 'Manufacturing', 'Rentals'],
    ['Pricing', 'Our Story', 'Contact Us'],
    ['Privacy', 'Cookie Policy'],
    ['YouTube', 'X (Twitter)', 'Instagram', 'Facebook'],
  ];

  const renderTopSection = () => {
    return (
      <div className={styles.topBlockStyles}>
        <h3 className={styles.headingStyles}>
          Subscribe to our Newsletter to Stay Up-to-date.
        </h3>
        {/* <p className={styles.descriptionTextStyles}>
          By subscribing to Newsletter, you agree to Trakr sending marketing
          communications, as disclosed in the <span>Privacy</span> and{' '}
          <span> Cookie policy</span>
        </p> */}
        <Input
          type="email"
          name="email"
          placeholder="Your email here*"
          // value=""
          rightImg={rightArrowIcon}
          customRightImgStyles={styles.inputRightImgStyles}
          customInputSubContainerStyles={styles.emailInputStyles}
        />
      </div>
    );
  };
  const renderBottomSection = () => {
    return (
      <div className={styles.bottomBlockStyles}>
        <div className={styles.cotactUsSubBlockStyles}>
          {footerContactUsData[0]?.map((item, index) => {
            return (
              <p className={styles.contactUsTextStyles} key={index}>
                {item}
              </p>
            );
          })}
        </div>
        <div className={styles.cotactUsSubBlockStyles}>
          {footerContactUsData[1]?.map((item, index) => {
            return (
              <p className={styles.contactUsTextStyles} key={index}>
                {item}
              </p>
            );
          })}
        </div>
        <div className={styles.cotactUsSubBlockStyles}>
          {footerContactUsData[2]?.map((item, index) => {
            return (
              <p className={styles.contactUsTextStyles} key={index}>
                {item}
              </p>
            );
          })}
        </div>
        <div className={styles.cotactUsSubBlockStyles}>
          {footerContactUsData[3]?.map((item, index) => {
            return (
              <p className={styles.contactUsTextStyles} key={index}>
                {item}
              </p>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div className={styles.footerContainerStyles}>
      {renderTopSection()}
      {renderBottomSection()}
    </div>
  );
};

export default Footer;
