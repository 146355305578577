import React from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';
import Image from 'components/image';

const Button = (props) => {
  const {
    icon,
    svgIcon,
    title,
    customBtnStyles,
    customBtnImgStyles,
    customBtnTitleStyles,
    type,
    disabled,
    onClick = () => {},
  } = props;

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(styles.btnStyles, customBtnStyles)}
    >
      {icon ? (
        <Image
          image={icon}
          altText={'imgIcon'}
          customImageStyles={classNames(
            styles.btnImgStyles,
            customBtnImgStyles
          )}
        />
      ) : (
        svgIcon && <div className={styles.svgIconStyles}>{svgIcon}</div>
      )}

      <p className={classNames(styles.btnTitleStyles, customBtnTitleStyles)}>
        {title}
      </p>
    </button>
  );
};

export default Button;
