import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';
const Image = (props) => {
  const {
    image,
    onClick = () => {},
    altText,
    customImageStyles,
    customObjectFitImgStyles,
    onContextMenu,
    imageStyles,
  } = props;
  return (
    <div
      className={classNames(styles.imageStyles, customImageStyles)}
      onClick={onClick}
    >
      <img
        src={image}
        alt={altText}
        className={classNames(styles.imageStyles, customObjectFitImgStyles)}
        onContextMenu={onContextMenu}
        style={imageStyles}
      />
    </div>
  );
};
export default Image;
