import Layout from 'layout';
import Categories from 'pages/categories';
import ContactUs from 'pages/contactus';
import OurStory from 'pages/ourstory';
import Pricing from 'pages/pricing';
import { useRoutes } from 'react-router-dom';

const AppRoutes = () => {
  return useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: 'categories',
          element: <Categories />,
        },
        {
          path: 'pricing',
          element: <Pricing />,
        },
        {
          path: 'ourstory',
          element: <OurStory />,
        },
        {
          path: 'contactus',
          element: <ContactUs />,
        },
      ],
    },
  ]);
};

export default AppRoutes;
