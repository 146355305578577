import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Modal = (props) => {
  const { isOpen, children, customModalStyles, customModalHideStyles } = props;

  useEffect(() => {
    // Function to toggle body scroll based on modal state
    const toggleBodyScroll = () => {
      if (isOpen) {
        // Disable body scroll
        document.body.style.overflow = 'hidden';
      } else {
        // Enable body scroll
        document.body.style.overflow = '';
      }
    };

    // Call the function when isOpen changes
    toggleBodyScroll();

    // Clean-up function to reset body scroll when component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return (
    <div
      className={`${classNames(styles.modalStyles, customModalStyles)} ${
        isOpen ? '' : classNames(styles.modalHideStyles, customModalHideStyles)
      }`}
    >
      {children}
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default Modal;
