import React, { useRef, useState, useEffect } from 'react';
import Button from 'components/button';
import Input from 'components/input';
import {
  mailIcon,
  mapIcon,
  leftArrowIcon,
  googlemeetIcon,
} from 'resources/images';
import emailjs from '@emailjs/browser';
import { emailValidationSchema } from 'constants/validations/validations';
import styles from './styles.module.css';
import Image from 'components/image';

const ContactUs = () => {
  const [errorMsg, setErrorMsg] = useState({
    name: '',
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    setIsLoading(true);
    emailjs
      .sendForm(
        'service_4rzxitg',
        'template_qnzyogg',
        form.current,
        'BCL0VqeQ06EXqocoa'
      )
      .then(
        (result) => {
          e.target.reset();
          setIsLoading(false);
        },
        (error) => {
          console.log('error', error);
          setIsLoading(false);
        }
      );
  };

  const handleValidation = (e) => {
    e.preventDefault();
    const newErrorMessages = {};

    if (e.target.name.value === '') {
      newErrorMessages.name = 'Please Enter Your Name';
    }

    if (e.target.email.value === '') {
      newErrorMessages.email = 'Please Enter Email';
    } else if (!emailValidationSchema.test(e.target.email.value)) {
      newErrorMessages.email = 'Please Enter a Valid Email';
    }

    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMsg({ ...errorMsg, ...newErrorMessages });
    } else {
      sendEmail(e);
      setErrorMsg({ name: '', email: '' });
    }
  };
  const handleBookaMeet = () => {
    window.location.href =
      'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1wgXN8MHMYtPgX3YyyKp9wzF_Uta_i2-MbgxhH1QSmbqQ7GzXw7iBAxp7Ah6qB8EptFKKi2GNo?gv=true';
  };
  const renderHeaderSection = () => {
    return (
      <div className={styles.headerContainerStyle}>
        <h1 className={styles.headerTextStyle}>We’d Love to Help.</h1>
        <p className={styles.headerDescTextStyle}>
          You have questions, comments or general queries? We value your
          suggestions. Feel free to get in touch with us.
        </p>
      </div>
    );
  };
  const renderMiddleSection = () => {
    return (
      <section className={styles.middleSectionStyle}>
        {renderMeetScheduleSection()}
        {renderFormContainer()}
        {renderOfficeSection()}
      </section>
    );
  };
  const renderMeetScheduleSection = () => {
    return (
      <div
        className={styles.sectionMeetContainerStyles}
        onClick={() => handleBookaMeet()}
      >
        <div className={styles.meetContainerStyle}>
          <div className={styles.iconContainerStyle}>
            <Image
              image={googlemeetIcon}
              customImageStyles={styles.iconStyle}
            />
          </div>
          <div className={styles.iconLeftContainerStyle}>
            <Image image={leftArrowIcon} customImageStyles={styles.iconStyle} />
          </div>
        </div>
        <div className={styles.cardContentWrapperStyle}>
          <p className={styles.cardHeaderTextStyle}>Schedule a Google Meet</p>
          <p className={styles.cardDescTextStyle}>
            Instantly schedule a Google Meet with our team at your convenience.
          </p>
        </div>
      </div>
    );
  };
  const renderFormContainer = () => {
    return (
      <div className={styles.sectionContainerStyles}>
        <div className={styles.cardContainerStyle}>
          <div className={styles.iconContainerStyle}>
            <Image image={mailIcon} customImageStyles={styles.iconStyle} />
          </div>
          <div className={styles.cardContentWrapperStyle}>
            <p className={styles.cardHeaderTextStyle}>Email support</p>
            <p className={styles.cardDescTextStyle}>
              Send us an email and we’ll get back to you soon.
            </p>
          </div>
        </div>
        <form
          className={styles.inputWithButtonStyles}
          ref={form}
          onSubmit={(e) => handleValidation(e)}
        >
          <Input
            label={'Full Name*'}
            type="text"
            name="name"
            placeholder="Your Full Name*"
            onFocus={() => {
              setErrorMsg({ ...errorMsg, name: '' });
            }}
            errorMessage={errorMsg.name}
            customInputStyles={styles.inputStyle}
            customLabelStyles={styles.labelTextStyle}
            customInputContainerStyles={styles.inputsContainerStyle}
            customInputSubContainerStyles={styles.fullNameInputSubStyles}
          />
          <Input
            label={'Work email*'}
            type="email"
            placeholder="Ex: campbell@outlook.com"
            name="email"
            onFocus={() => {
              setErrorMsg({ ...errorMsg, email: '' });
            }}
            errorMessage={errorMsg.email}
            customLabelStyles={styles.labelTextStyle}
            customInputStyles={styles.inputStyle}
            customInputSubContainerStyles={styles.fullNameInputSubStyles}
            customInputContainerStyles={styles.inputsContainerStyle}
          />
          <div className={styles.inputsContainerStyle}>
            <p className={styles.labelTextStyle}>What you want to say/ask*</p>
            <textarea
              name="message"
              placeholder="Write here ..."
              className={styles.textAreaModalStyles}
            />
          </div>
          <p className={styles.freeDemoTextStyle}>
            If you want to book a free demo,&nbsp;
            <a className={styles.clickHereTextStyle}>click here</a>
          </p>
          <Button
            title={isLoading ? 'Sending...' : 'Send Message'}
            disabled={isLoading}
            type="submit"
            customBtnStyles={
              isLoading
                ? styles.disableSubmitBtnStyles
                : styles.submitFormBtnStyles
            }
            customBtnTitleStyles={styles.submitFormBtnTitleStyles}
          />
        </form>
      </div>
    );
  };
  const renderOfficeSection = () => {
    return (
      <div className={styles.sectionContainerStyles}>
        <div className={styles.cardContainerStyle}>
          <div className={styles.iconContainerStyle}>
            <Image image={mapIcon} customImageStyles={styles.iconStyle} />
          </div>
          <div className={styles.cardContentWrapperStyle}>
            <p className={styles.cardHeaderTextStyle}>Visit our office</p>
            <p className={styles.cardDescTextStyle}>
              Open from 10am to 6pm CET. Mon-Fri.
            </p>
          </div>
        </div>
        <div className={styles.mapContainerStyle}>
          <iframe
            src={
              'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2228.649095980131!2d12.688687976794707!3d56.0420643731927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6908b91c83a30293%3A0x520846aa209d69ae!2sVilje%20Tech!5e0!3m2!1sen!2sin!4v1726576541821!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'
            }
            style={{
              width: '100%',
              height: '100%',
              border: '0',
              borderRadius: '12px',
              allowfullscreen: '',
              loading: 'lazy',
              referrerpolicy: 'no-referrer-when-downgrade',
            }}
          ></iframe>
        </div>
      </div>
    );
  };
  return (
    <main className={styles.mainContainerStyle}>
      {renderHeaderSection()}
      {renderMiddleSection()}
    </main>
  );
};

export default ContactUs;
