import React, { useEffect, useState } from 'react';
import Image from 'components/image';
import {
  closeIcon,
  hambergerIcon,
  logoForMobile,
  trakrLogo,
} from 'resources/images';
import Button from 'components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

const Header = () => {
  // routing:
  const location = useLocation();
  const navigate = useNavigate();
  const menuItemsData = ['Categories', 'Pricing', 'Our Story'];
  const [showHambergerMenu, setShowHambergerMenu] = useState(false);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/categories');
    }
  }, [location.pathname, navigate]);

  const handleMenuItemClick = (item) => {
    switch (item) {
      case 'Categories':
        navigate('/categories');
        break;

      case 'Pricing':
        navigate('/pricing');
        break;

      case 'Our Story':
        navigate('/ourstory');
        break;

      default:
        break;
    }
    setShowHambergerMenu(false);
  };

  const renderLeftSection = () => {
    return (
      <Image
        image={trakrLogo}
        onClick={() => navigate('/categories')}
        altText="trakrLogo"
        customImageStyles={styles.trakrLogoStyles}
      />
    );
  };

  const renderMiddleSection = () => {
    return (
      <div className={styles.middleMenuBlockStyles}>
        {menuItemsData?.map((item, index) => {
          const isActiveMenu = location.pathname.includes(
            item?.toLowerCase().replace(/\s/g, '')
          );
          return (
            <p
              className={
                isActiveMenu
                  ? styles.activeMenuItemStyles
                  : styles.menuItemsStyles
              }
              key={index}
              onClick={() => handleMenuItemClick(item)}
            >
              {item}
            </p>
          );
        })}
      </div>
    );
  };

  const renderRightSection = () => {
    return (
      <Button
        title="Contact Us"
        customBtnStyles={styles.contactBtnStyles}
        onClick={() => navigate('/contactus')}
      />
    );
  };

  const renderHambergerMenuSection = () => {
    return (
      <div className={styles.hamberMenuBlockStyles}>
        <Image
          image={logoForMobile}
          onClick={() => navigate('/categories')}
          altText="trakrLogo"
          customImageStyles={styles.mobileLogoStyles}
          customObjectFitImgStyles={styles.trakrImgStyle}
        />
        <Image
          image={hambergerIcon}
          onClick={() => setShowHambergerMenu(!showHambergerMenu)}
          altText="menu"
          customImageStyles={styles.hambergerMenuImgStyles}
        />
      </div>
    );
  };

  const renderMobileMenuItemsSection = () => {
    return (
      <div className={styles.menuItemsBlockStyles}>
        <Image
          image={closeIcon}
          onClick={() => setShowHambergerMenu(!showHambergerMenu)}
          altText="menu"
          customImageStyles={styles.closeIconStyles}
        />
        {menuItemsData?.map((item, index) => {
          const isActiveMenu = location.pathname.includes(
            item?.toLowerCase().replace(/\s/g, '')
          );
          return (
            <p
              className={
                isActiveMenu
                  ? styles.mobileActiveMenuItemStyles
                  : styles.mobileMenuItemsStyles
              }
              key={index}
              onClick={() => handleMenuItemClick(item)}
            >
              {item}
            </p>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className={styles.headerContainerStyles}>
        {renderLeftSection()}
        {/* {renderMiddleSection()} */}
        {location.pathname !== '/contactus' && <> {renderRightSection()}</>}
      </div>
      {renderHambergerMenuSection()}
      {showHambergerMenu && renderMobileMenuItemsSection()}
    </>
  );
};

export default Header;
