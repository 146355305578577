import React from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';
import Image from 'components/image';
import { whiteRightChevronIcon } from 'resources/images';

const TrakrAssistCard = (props) => {
  const {
    image,
    imageAltText,
    title,
    options,
    bottomLeftImg,
    leftImgAltText,
    rightArrow,
    onClick,
    onClickLeftArrowImg,
    isActive,
    customCardContainerStyles,
    customImageBlockStyles,
    customLeftImgBlockStyles,
  } = props;

  const renderTopSection = () => {
    return (
      <div
        className={classNames(styles.imageBlockStyles, customImageBlockStyles)}
      >
        <Image
          image={image}
          altText={imageAltText}
          customImageStyles={styles.topImageStyles}
        />
      </div>
    );
  };

  const renderBottomSection = () => {
    return (
      <div className={styles.bottomBlockStyles}>
        <h5 className={styles.headingStyles}>{title}</h5>
        <div className={styles.optionsBlockStyles}>
          {options.map((options, index) => {
            return (
              <ul key={index}>
                <li className={styles.optionsTextStyles}>{options}</li>
              </ul>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        styles.mainCardContainerStyles,
        customCardContainerStyles
      )}
    >
      <div className={styles.trakrAssistCardContainerStyles}>
        {renderTopSection()}
        {renderBottomSection()}
      </div>
      {rightArrow && (
        <div
          className={
            isActive
              ? styles.activeLeftImgBlockStyles
              : styles.leftImgBlockStyles
          }
          onClick={onClickLeftArrowImg}
        >
          <Image
            image={isActive ? whiteRightChevronIcon : bottomLeftImg}
            altText={leftImgAltText}
            customImageStyles={styles.leftImageStyles}
            onClick={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default TrakrAssistCard;
