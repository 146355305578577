import React, { useState, useEffect, useRef } from 'react';
import Image from 'components/image';
import Button from 'components/button';
import {
  bannerImg,
  closeIcon,
  downArrowIcon,
  graphIcon,
  rightArrowUpBgIcon,
  rightChevronIcon,
  sadEmojiIcon,
  starIcon,
} from 'resources/images';
import TrakrAssistCard from 'components/cards/trakrAssistCard';
import Popup from 'components/modal';
import Modal from 'components/modal';
import Input from 'components/input';
import { emailValidationSchema } from 'constants/validations/validations';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

const Categories = () => {
  const navigate = useNavigate();
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [selectBtn, setSelectBtn] = useState('Book Free Demo');
  const [bookDemoModal, setBookDemoModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    name: '',
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const form = useRef();
  const brandsData = [
    {
      text: 'Helsingborg City',
    },
    {
      text: 'Minc',
    },
    {
      text: 'Vinnova',
    },
    {
      text: 'Trmek',
    },
    {
      text: 'Hetch',
    },
  ];
  const duplicatedBrands = [...brandsData, ...brandsData];
  const sendEmail = (e) => {
    setIsLoading(true);
    emailjs
      .sendForm(
        'service_4rzxitg',
        'template_qnzyogg',
        form.current,
        'BCL0VqeQ06EXqocoa'
      )
      .then(
        (result) => {
          e.target.reset();
          setBookDemoModal(false);
          setIsLoading(false);
        },
        (error) => {
          console.log('error', error);
          setIsLoading(false);
        }
      );
  };

  const handleValidation = (e) => {
    e.preventDefault();
    const newErrorMessages = {};

    if (e.target.name.value === '') {
      newErrorMessages.name = 'Please Enter Your Name';
    }

    if (e.target.email.value === '') {
      newErrorMessages.email = 'Please Enter Email';
    } else if (!emailValidationSchema.test(e.target.email.value)) {
      newErrorMessages.email = 'Please Enter a Valid Email';
    }

    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMsg({ ...errorMsg, ...newErrorMessages });
    } else {
      sendEmail(e);
      setErrorMsg({ name: '', email: '' });
    }
  };

  const handleModalClose = () => {
    setBookDemoModal(false);
    setErrorMsg({});
  };

  //   function to - handleBlur to display error messages
  // const handleBlur = (field) => {
  //   const newErrorMessages = { ...errorMessage };
  //   if (
  //     (field === 'email' && loginData.email === '') || !emailValidationSchema.test(loginData.email)
  //   ) {
  //     newErrorMessages.email = 'Please Enter Valid Email';
  //   }
  //   if (field === 'password' && loginData.password === '') {
  //     newErrorMessages.password = 'Please Enter Password';
  //   }
  //   setErrorMessage(newErrorMessages);
  // };

  const trakrAssistCardData = [
    {
      image: sadEmojiIcon,
      title: 'Problems with Manual Asset Management for Offices:',
      options: ['Costly', 'Time-Consuming', 'Error Prone'],
    },
    {
      image: graphIcon,
      title: 'Gains with Trakr’s SaaS based Asset Management Platform:',
      options: [
        'Increase Work Efficiency by 40%',
        '25% increase in Product Life Cycle',
        'Increase Production Capacity by 20%',
      ],
    },
    {
      image: starIcon,
      title: 'Features that set Trakr apart from the competition:',
      options: [
        'Reimagined Tickets',
        'Maintenance Management',
        'Asset Tracking',
        'Reservation System',
        'API Integration',
      ],
    },
  ];

  const btnData = ['Book Free Demo', 'Learn More'];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleBtnClick = (item) => {
    setSelectBtn(item);
  };

  const renderTopSection = () => {
    return (
      <div className={styles.topSectionStyles}>
        {renderBannerContentSection()}
        {renderBannerImgSection()}
        {renderBannerBrandSection()}
      </div>
    );
  };

  const renderBannerContentSection = () => {
    return (
      <div className={styles.bannerContentStyles}>
        <div className={styles.bannerTitleStyles}>
          <h2 className={styles.bannerHeaderStyles}>
            Asset Management Redefined.
          </h2>
          <p className={styles.bannerDescpStyles}>
            Our vision is to redefine the asset and maintenance management and
            help companies reach their circular economy goals.
          </p>
        </div>
        <div className={styles.bannerBtnStyles}>
          <Button
            title="Book Free Demo"
            customBtnStyles={styles.activeBtnStyles}
            customBtnTitleStyles={styles.activeBtnTitleStyles}
            onClick={() => navigate('/contactus')}
          />
          {/* {btnData?.map((item, index) => {
            return (
              <Button
                key={index}
                title={item}
                onClick={() => handleBtnClick(item)}
                customBtnStyles={
                  selectBtn === item
                    ? styles.activeBtnStyles
                    : styles.learnMoreBtnStyles
                }
                customBtnTitleStyles={
                  selectBtn == item
                    ? styles.activeBtnTitleStyles
                    : styles.inActiveBtnTitleStyles
                }
              />
            );
          })} */}
        </div>
      </div>
    );
  };

  const renderBannerImgSection = () => {
    return (
      <Image
        customImageStyles={styles.bannerImgContainerStyles}
        image={bannerImg}
        altText="banner-img"
        customObjectFitImgStyles={styles.bannerImgStyles}
      />
    );
  };

  const renderBannerBrandSection = () => {
    return (
      <div className={styles.bannerBrandSectionStyles}>
        <div className={styles.scrollContainerStyle}>
          {duplicatedBrands.map((item, index) => {
            return (
              <div key={index} className={styles.brandBackGroundStyles}>
                {/* <div className={styles.brandImageStyles}> */}
                {/* <img src={item.image} className={styles.brandLogoStyles} /> */}
                <h2 className={styles.headerTextStyle}>{item.text}</h2>
                {/* </div> */}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderBottomSection = () => {
    return (
      <div className={styles.bottomBlockStyles}>
        {renderTrakrAssistSection()}
        {renderTrakrAssistCardsSection()}
      </div>
    );
  };

  const renderTrakrAssistSection = () => {
    return (
      <div className={styles.trakrAssitContentBlockStyles}>
        <h5 className={styles.trakrAssistHeadingStyles}>
          How Trakr Can Assist Your Business.
        </h5>
        <div className={styles.viewTrakrOfficeDropDownStyles}>
          <p className={styles.viewTrakrOfficeTextStyles}>
            You’re viewing Trakr for <span>Manufacturing</span>
          </p>
          {/* <Image
            image={downArrowIcon}
            onClick={() => {}}
            altText="dropDownIcon"
            customImageStyles={styles.dropDownIconStyles}
          /> */}
        </div>
      </div>
    );
  };

  const renderTrakrAssistCardsSection = () => {
    return (
      <div className={styles.trakrAssistMainCardStyles}>
        <div className={styles.trakrAssistCardBlockStyles}>
          {trakrAssistCardData?.map((item, index) => {
            return (
              <TrakrAssistCard
                key={index}
                image={item?.image}
                imageAltText={item?.title}
                title={item?.title}
                options={item?.options}
                bottomLeftImg={rightChevronIcon}
                leftImgAltText="rightArrowIcon"
                customCardContainerStyles={
                  index === 0
                    ? styles.manualAssetCardStyles
                    : index === 1
                    ? styles.saasBasedCardStyles
                    : index === 2 && styles.featuresCardStyles
                }
                isActive={activeCardIndex === index}
                onClickLeftArrowImg={() => setActiveCardIndex(index)}
              />
            );
          })}
        </div>
        {/* <div className={styles.moreTrakerDropDownStyles}>
          <p className={styles.moreTrakrOfficeTextStyles}>
            More about Trakr for Offices
          </p>
          <Image
            image={rightArrowUpBgIcon}
            onClick={() => {}}
            altText="dropDownIcon"
            customImageStyles={styles.rightArrowUpIconStyles}
          />
        </div> */}
      </div>
    );
  };

  const renderBookDemoModalSection = () => {
    return (
      <>
        {bookDemoModal && (
          <Modal
            isOpen={bookDemoModal}
            customModalStyles={styles.bookDemoModalStyles}
            // customModalHideStyles={styles.bookModalHideStyles}
          >
            <div className={styles.bookDemoContainerStyles}>
              <div className={styles.bookDemoCloseModalStyles}>
                <img
                  onClick={() => handleModalClose()}
                  src={closeIcon}
                  alt="close-icon"
                  className={styles.closeImageStyles}
                />
              </div>
              {/* <div className={styles.modalTextWithInputStyles}> */}
              <div className={styles.modalTextStyles}>
                <h2 className={styles.modalTitleStyles}>
                  Join the <span>waiting list</span> and we’ll inform you when
                  our product is market-ready!
                </h2>
                <p className={styles.modalDescpStyles}>
                  If you are interested to know more about trakr, you can{' '}
                  <span>book a demo meeting</span>
                </p>
              </div>
              {/* </div> */}
              <form
                className={styles.inputWithButtonStyles}
                ref={form}
                onSubmit={(e) => handleValidation(e)}
              >
                <div className={styles.inputModalStyles}>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Your Full Name*"
                    onFocus={() => {
                      setErrorMsg({ ...errorMsg, name: '' });
                    }}
                    errorMessage={errorMsg.name}
                    customInputSubContainerStyles={
                      styles.fullNameInputSubStyles
                    }
                  />
                  <Input
                    type="email"
                    placeholder="Your Email Id*"
                    name="email"
                    onFocus={() => {
                      setErrorMsg({ ...errorMsg, email: '' });
                    }}
                    errorMessage={errorMsg.email}
                    customInputSubContainerStyles={
                      styles.fullNameInputSubStyles
                    }
                  />

                  <textarea
                    name="message"
                    placeholder="Your Message here (Optional)"
                    className={styles.textAreaModalStyles}
                  />
                </div>
                <Button
                  title={isLoading ? 'Sending...' : 'Submit form'}
                  disabled={isLoading}
                  type="submit"
                  customBtnStyles={
                    isLoading
                      ? styles.disableSubmitBtnStyles
                      : styles.submitFormBtnStyles
                  }
                  customBtnTitleStyles={styles.submitFormBtnTitleStyles}
                />
              </form>
            </div>
          </Modal>
        )}
      </>
    );
  };

  return (
    <div className={styles.categoryContainerStyles}>
      {renderTopSection()}
      {renderBottomSection()}
      {renderBookDemoModalSection()}
    </div>
  );
};

export default Categories;
