import React, { useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import Image from 'components/image';
import styles from './styles.module.css';

const Input = (props) => {
  const {
    label,
    type,
    name,
    placeholder,
    value,
    leftImg,
    rightImg,
    rightText,
    bottomText,
    autoComplete,
    tabIndex,
    maxLength,
    onKeyUp,
    min,
    max,
    errorMessage,
    onFocus = () => {},
    onBlur = () => {},
    onChange = () => {},
    onPaste = () => {},
    onClickRightImg = () => {},
    onClickRightText = () => {},
    onClickBottomText = () => {},
    customInputContainerStyles,
    customLabelStyles,
    customInputSubContainerStyles,
    customLeftImgStyles,
    customInputStyles,
    customRightImgStyles,
    customFocusInputStyles,
  } = props;

  //   states:
  const [isFocussed, setIsFocussed] = useState(false);

  //   functions to handle focus and blur state:
  const handleFocus = (e) => {
    setIsFocussed(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e) => {
    setIsFocussed(false);
    if (onBlur) onBlur(e);
  };

  return (
    <div
      className={classNames(
        styles.inputContainerStyles,
        customInputContainerStyles
      )}
    >
      {label && (
        <label
          className={classNames(styles.labelTextStyles, customLabelStyles)}
        >
          {label}
        </label>
      )}
      <div
        className={classNames(
          isFocussed
            ? classNames(styles.focusInputStyles, customFocusInputStyles)
            : styles.inputSubContainerStyles,
          customInputSubContainerStyles
        )}
      >
        {leftImg && (
          <Image
            image={leftImg}
            altText="leftImg"
            customImageStyles={styles.imageStyles}
          />
        )}
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          // autoComplete="off"
          tabIndex={tabIndex}
          maxLength={maxLength}
          onKeyUp={onKeyUp}
          onPaste={onPaste}
          min={min}
          max={max}
          className={classNames(styles.inputStyles, customInputStyles)}
        />
        {rightImg && (
          <Image
            image={rightImg}
            altText="rightImg"
            onClick={onClickRightImg}
            customImageStyles={classNames(
              styles.imageStyles,
              customRightImgStyles
            )}
          />
        )}
        {rightText && (
          <p className={styles.rightTextStyles} onClick={onClickRightText}>
            {rightText}
          </p>
        )}
      </div>
      {errorMessage && (
        <p className={styles.errorMsgTextStyles}>{errorMessage}</p>
      )}
      {bottomText && (
        <p className={styles.bottomTextStyles} onClick={onClickBottomText}>
          {bottomText}
        </p>
      )}
    </div>
  );
};

export default Input;

Input.propTypes = {
  label: propTypes.string,
  type: propTypes.string,
  name: propTypes.string,
  placeholder: propTypes.string,
  value: propTypes.any,
  rightText: propTypes.string,
  bottomText: propTypes.string,
  onFocus: propTypes.func,
  onBlur: propTypes.func,
  onChange: propTypes.func,
};
